import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "square", "status", "alert", "alertTitle", "alertDescription" ]

  connect() {
    this.board = Array(9).fill(null)
    this.xIsNext = true
    this.gameOver = false
    this.updateStatus()
  }

  handleClick(event) {
    if (this.gameOver) return
    const index = event.target.dataset.index
    if (this.board[index]) return

    this.board[index] = 'X'
    event.target.textContent = 'X'
    this.xIsNext = false
    this.checkGameState('X')

    if (!this.gameOver) {
      setTimeout(() => this.computerMove(), 500)
    }
  }

  computerMove() {
    const emptySquares = this.board.reduce((acc, val, idx) => 
      val === null ? acc.concat(idx) : acc, [])
    if (emptySquares.length === 0) return

    const randomIndex = Math.floor(Math.random() * emptySquares.length)
    const computerChoice = emptySquares[randomIndex]

    this.board[computerChoice] = 'O'
    this.squareTargets[computerChoice].textContent = 'O'
    this.xIsNext = true
    this.checkGameState('O')
  }

  checkGameState(player) {
    const winner = this.calculateWinner()
    if (winner) {
      this.gameOver = true
      this.showAlert(winner === 'X' ? '¡Has ganado!' : 'La computadora ha ganado')
    } else if (this.board.every(Boolean)) {
      this.gameOver = true
      this.showAlert('¡Empate!')
    }
    this.updateStatus()
  }

  calculateWinner() {
    const lines = [
      [0, 1, 2], [3, 4, 5], [6, 7, 8],
      [0, 3, 6], [1, 4, 7], [2, 5, 8],
      [0, 4, 8], [2, 4, 6]
    ]
    for (let i = 0; i < lines.length; i++) {
      const [a, b, c] = lines[i]
      if (this.board[a] && this.board[a] === this.board[b] && this.board[a] === this.board[c]) {
        return this.board[a]
      }
    }
    return null
  }

  updateStatus() {
    const winner = this.calculateWinner()
    if (winner) {
      this.statusTarget.textContent = `¡${winner === 'X' ? 'Has ganado!' : 'La computadora ha ganado!'}`
    } else if (this.board.every(Boolean)) {
      this.statusTarget.textContent = '¡Empate!'
    } else {
      this.statusTarget.textContent = `Turno de: ${this.xIsNext ? 'Jugador (X)' : 'Computadora (O)'}`
    }
  }

  showAlert(message) {
    this.alertTarget.style.display = 'block'
    this.alertTitleTarget.textContent = '¡Juego Terminado!'
    this.alertDescriptionTarget.textContent = message
  }

  resetGame() {
    this.board = Array(9).fill(null)
    this.xIsNext = true
    this.gameOver = false
    this.squareTargets.forEach(square => square.textContent = '')
    this.alertTarget.style.display = 'none'
    this.updateStatus()
  }
}