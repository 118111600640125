// app/javascript/controllers/profile_tabs_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "content"]

  connect() {
    // Mostrar el primer tab por defecto
    this.showTab(0)
  }

  switch(event) {
    const index = this.tabTargets.indexOf(event.currentTarget)
    this.showTab(index)
  }

  showTab(index) {
    this.tabTargets.forEach((tab, i) => {
      tab.classList.toggle("bg-indigo-600", i === index)
      tab.classList.toggle("text-white", i === index)
      tab.classList.toggle("text-gray-400", i !== index)
      tab.classList.toggle("hover:text-white", i !== index)
    })

    this.contentTargets.forEach((content, i) => {
      content.classList.toggle("hidden", i !== index)
    })
  }
}