
// app/javascript/controllers/image_gallery_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["notification", "likeCount", "favoriteCount", "downloadCount", "modal", "promptCopied", "mainImage", "alert", "promptText"]
  
   // this.likeButtonTarget.addEventListener("click", this.toggleLike.bind(this))
  connect() {
   
  }

  openModal(event) {
    console.log("click en open modal")
    const imageId = event.currentTarget.dataset.imageId
    fetch(`/ai_images/${imageId}/modal`)
      .then(response => response.text())
      .then(html => {
        this.modalTarget.innerHTML = html
        this.modalTarget.classList.remove('hidden')
      })
  }

  updateMainImage(event) {
    const newImageUrl = event.currentTarget.dataset.imageUrl;
    const imageId = event.currentTarget.dataset.imageId;

    // Actualiza la imagen principal
    if (this.mainImageTarget) {
      this.mainImageTarget.src = newImageUrl;
      this.mainImageTarget.dataset.imageId = imageId;

      // Muestra un mensaje de éxito (opcional)
      this.showAlert(`¡Imagen ${imageId} actualizada!`);
    } else {
      console.error("mainImageTarget no está definido");
    }
  }


  showAlert(message) {
    const alert = this.alertTarget;
    alert.textContent = message;
    alert.classList.remove("hidden");

    // Ocultar el mensaje después de 2 segundos
    setTimeout(() => {
      alert.classList.add("hidden");
    }, 2000);
  }


  closeModal() {
    this.modalTarget.classList.add('hidden')
    this.modalTarget.innerHTML = ''
  }



  toggleLike(event) {
    console.log("Entro a like")
    event.preventDefault()
      const imageId = this.likeButtonTarget.dataset.imageId
    console.log("ImageIA" + imageId )
    const url = `/ai_images/${imageId}/like`
    this.performAction(url, 'POST')
  }

  saveImage(event) {
    event.preventDefault()
    const imageId = event.currentTarget.dataset.imageId
    const url = `/ai_images/${imageId}/favorite`
    this.performAction(url, 'POST')
  }

  downloadImage(event) {
    event.preventDefault()
    const imageId = event.currentTarget.dataset.imageId
    const imageType = event.currentTarget.dataset.imageType
    const url = `/ai_images/${imageId}/download?type=${imageType}`

    this.performAction(url, 'downloadCount', imageId)
    
    // Use fetch to get the download URL
    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.download_url) {
          window.open(data.download_url, '_blank')
        } else {
          console.error('Download URL not found in response')
        }
      })
      .catch(error => console.error('Error:', error))
  }




  copyPrompt() {
    const button = this.promptCopiedTarget
    const promptText = this.promptTextTarget.textContent
    const originalContent = button.innerHTML
    
    navigator.clipboard.writeText(promptText)
      .then(() => {
        // Cambiar al ícono de check y estilo
        button.innerHTML = `
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
        `
        button.classList.remove('bg-indigo-100', 'text-indigo-600')
        button.classList.add('bg-green-100', 'text-green-600')
        
        // También podemos mostrar un mensaje flotante
        if (this.hasNotificationTarget) {
          this.notificationTarget.textContent = "¡Prompt copiado!"
          this.notificationTarget.classList.remove('hidden')
          this.notificationTarget.classList.add('flex')
        }
        
        // Restaurar después de 1.5 segundos
        setTimeout(() => {
          button.innerHTML = originalContent
          button.classList.remove('bg-green-100', 'text-green-600')
          button.classList.add('bg-indigo-100', 'text-indigo-600')
          
          if (this.hasNotificationTarget) {
            this.notificationTarget.classList.add('hidden')
            this.notificationTarget.classList.remove('flex')
          }
        }, 1500)
      })
      .catch(err => {
        console.error('Error al copiar el texto:', err)
        button.classList.remove('bg-indigo-100', 'text-indigo-600')
        button.classList.add('bg-red-100', 'text-red-600')
        
        if (this.hasNotificationTarget) {
          this.notificationTarget.textContent = "Error al copiar"
          this.notificationTarget.classList.remove('hidden')
          this.notificationTarget.classList.add('flex')
        }
        
        setTimeout(() => {
          button.classList.remove('bg-red-100', 'text-red-600')
          button.classList.add('bg-indigo-100', 'text-indigo-600')
          
          if (this.hasNotificationTarget) {
            this.notificationTarget.classList.add('hidden')
            this.notificationTarget.classList.remove('flex')
          }
        }, 1500)
      })
  }


 performAction(url, method) {
    fetch(url, {
      method: method,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Accept': 'text/vnd.turbo-stream.html, application/json'
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then(html => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const turboStream = doc.querySelector('turbo-stream');
      if (turboStream) {
        Turbo.renderStreamMessage(turboStream.outerHTML);
      }
    })
    .catch(error => {
      console.error('Error:', error)
      this.showErrorMessage("Hubo un problema al procesar tu solicitud. Por favor, inténtalo de nuevo.")
    })
  }

  showErrorMessage(message) {
    // Implementa la lógica para mostrar un mensaje de error al usuario
    // Por ejemplo, podrías tener un elemento en el DOM para mostrar mensajes de error
    const errorElement = document.getElementById('error-message')
    if (errorElement) {
      errorElement.textContent = message
      errorElement.classList.remove('hidden')
      setTimeout(() => {
        errorElement.classList.add('hidden')
      }, 5000)
    }
  }

  showNotification() {
    const notification = this.notificationTarget
    notification.classList.remove('translate-y-full', 'opacity-0')
    setTimeout(() => {
      notification.classList.add('translate-y-full', 'opacity-0')
    }, 3000)
  }
}