// app/javascript/controllers/memory_game_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["board", "score", "message", "restartButton"]
  static values = { size: Number }

  connect() {
    this.initializeGame()
  }

  initializeGame() {
    this.cards = [
      { name: "Web3", icon: "#icon-web3" },
      { name: "3D Audio", icon: "#icon-3d-audio" },
      { name: "AI Image", icon: "#icon-ai-image" },
      { name: "AI Video", icon: "#icon-ai-video" },
      { name: "AI Text", icon: "#icon-ai-text" },
      { name: "Cryptocurrency", icon: "#icon-crypto" },
      { name: "Productivity", icon: "#icon-productivity" },
      { name: "AI Code", icon: "#icon-ai-code" },
    ]
    this.shuffleCards()
    this.renderBoard()
    this.score = 0
    this.matchedPairs = 0
    this.updateScore()
    this.messageTarget.classList.add("hidden")
    this.restartButtonTarget.classList.add("hidden")
  }

  shuffleCards() {
    let shuffled = this.cards.concat(this.cards).sort(() => 0.5 - Math.random())
    this.gameCards = shuffled.slice(0, this.sizeValue * this.sizeValue)
  }

  renderBoard() {
    this.boardTarget.innerHTML = ""
    this.gameCards.forEach((card, index) => {
      const cardElement = document.createElement("div")
      cardElement.classList.add("card", "bg-otro", "rounded-lg", "shadow-md", "cursor-pointer", "transition-transform", "duration-300", "transform")
      cardElement.dataset.index = index
      cardElement.innerHTML = `
        <div class="card-back flex items-center justify-center text-4xl text-blue-500">?</div>
        <div class="card-front flex flex-col items-center justify-center bg-otro">
          <svg class="icon w-12 h-12 text-blue-500">
            <use href="${card.icon}"></use>
          </svg>
          <div class="card-name mt-2 text-sm text-center">${card.name}</div>
        </div>
      `
      cardElement.addEventListener("click", () => this.flipCard(cardElement, card))
      this.boardTarget.appendChild(cardElement)
    })
  }

  flipCard(cardElement, card) {
    if (this.flippedCards && this.flippedCards.length === 2) return
    if (!this.flippedCards) this.flippedCards = []
    if (cardElement.classList.contains("flipped")) return

    cardElement.classList.add("flipped")
    this.flippedCards.push({ element: cardElement, card: card })

    if (this.flippedCards.length === 2) {
      setTimeout(() => this.checkMatch(), 1000)
    }
  }

  checkMatch() {
    const [card1, card2] = this.flippedCards
    if (card1.card.name === card2.card.name) {
      this.score += 10
      this.matchedPairs++
      card1.element.classList.add("matched", "bg-green-200")
      card2.element.classList.add("matched", "bg-green-200")
      if (this.matchedPairs === this.gameCards.length / 2) {
        this.showVictoryMessage()
      }
    } else {
      card1.element.classList.remove("flipped")
      card2.element.classList.remove("flipped")
    }
    this.updateScore()
    this.flippedCards = null
  }

  updateScore() {
    this.scoreTarget.textContent = `Puntuación: ${this.score}`
  }

  showVictoryMessage() {
    this.messageTarget.textContent = "¡Felicidades! Has ganado el juego."
    this.messageTarget.classList.remove("hidden")
    this.restartButtonTarget.classList.remove("hidden")
    this.celebrateVictory()
  }

  celebrateVictory() {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  }

  restart() {
    this.initializeGame()
  }
}