import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggleButton", "detailsContent", "container", "buttonText", "icon", "mainImage"]

  connect() 
  {
    this.isOpen = false
  }

  toggleDetails() {
        this.isOpen = !this.isOpen
        this.detailsContentTarget.classList.toggle("hidden", !this.isOpen)
        this.detailsContentTarget.style.maxHeight = this.isOpen ? `${this.detailsContentTarget.scrollHeight}px` : '0';
        this.buttonTextTarget.textContent = this.isOpen ? "Ocultar detalles" : "Mostrar detalles"
        this.iconTarget.classList.toggle("rotate-180", this.isOpen)
  }

  downloadImage() {
    alert("Enrto downloaded")
    const mainImage = this.mainImageTarget.querySelector('img');
    if (mainImage) {
        const imageUrl = mainImage.src;
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = 'generated_image.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
      console.error('No hay imagen principal para descargar');
    }
  }
}