import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flash"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.hideFlash()
    }, 5000) // Ocultar después de 5 segundos
  }

  hideFlash() {
    this.element.classList.add('opacity-0')
    setTimeout(() => {
      this.element.remove()
    }, 500) // Remover después de la transición de 0.5 segundos
  }
}
