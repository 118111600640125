import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["table"]; // Targets defined here 
  connect() {
    // Initially hide the categories element
    console.log("PeticionCategorias");
   
  }

  loadCategories() {
    // Fetch categories using AJAX or Fetch API
    fetch('/categories.json') // Replace with your endpoint
      .then(response => response.json())
      .then(categories => {
        // Update DOM with loaded categories (see below)
        console.log("Categorias"+ JSON.stringify(categories))
        this.categories = categories; // Store categories in a variable
        this.updateCategories(categories);
      })
      .catch(error => console.error(error));
  }

  loadCategoriespersonal() {
    // Fetch categories using AJAX or Fetch API
    fetch('/categories.json') // Replace with your endpoint
      .then(response => response.json())
      .then(categories => {
        // Update DOM with loaded categories (see below)
        console.log("Categorias"+ JSON.stringify(categories))
        this.categories = categories; // Store categories in a variable
        this.updateCategories(categories);
 
      })
      .catch(error => console.error(error));
  }



  updateCategories(categories) {
    
   
    const tableTarget = this.element.querySelector('[data-categorias-peticion-target="table"]'); 

    categories.forEach(category => {
      const row = document.createElement('tr');
    
      row.innerHTML = `
        <td><strong class="badge text-pink-400!">${category.id}</strong></td>
        <td><strong class="badge text-pink-400!">${category.name_category}</strong></td>
        <td class="text-center py-4">
          <strong class="badge text-pink-400!"><a href="https://wpmarmite.com/en/change-url-categories-wordpress/">Edit</a></strong>
        </td>
        <td class="text-center py-4">
          <strong class="badge text-pink-400!"><a href="https://pluginsforwp.com/blog/remove-category-from-wordpress-url/" data-method="delete">Destroy</a></strong>
        </td>
      `;
    
      tableTarget.appendChild(row);
    });


  }
}