import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["pacman", "ghost", "score", "gameOver", "pellet"]
  static values = { 
    boardWidth: Number,
    boardHeight: Number
  }

  connect() {
    this.pacmanPosition = { x: 0, y: 0 }
    this.direction = 'right'
    this.score = 0
    this.gameIsOver = false
    this.mouthOpen = true
    this.ghostPositions = this.initializeGhostPositions()
    this.setupKeyListeners()
    this.startGame()
  }

  startGame() {
    this.gameIsOver = false
    this.score = 0
    this.updateScore()
    this.gameOverTarget.classList.add('hidden')
    this.moveInterval = setInterval(() => {
      this.movePacman()
      this.moveGhosts()
      this.checkCollisions()
      this.animatePacmanMouth()
    }, 100)
    this.respawnGhosts()
    this.respawnPellets()
  }

  initializeGhostPositions() {
    return this.ghostTargets.map((ghost, index) => ({
      x: (index + 1) * 100,
      y: (index + 1) * 100,
      direction: ['up', 'down', 'left', 'right'][Math.floor(Math.random() * 4)]
    }))
  }

  setupKeyListeners() {
    this.boundHandleKeyPress = this.handleKeyPress.bind(this)
    document.addEventListener('keydown', this.boundHandleKeyPress)
  }

  disconnect() {
    document.removeEventListener('keydown', this.boundHandleKeyPress)
    clearInterval(this.moveInterval)
  }

  handleKeyPress(e) {
    if (this.gameIsOver && e.key === 'Enter') {
      this.restartGame()
      return
    }

    switch(e.key) {
      case 'ArrowUp': this.direction = 'up'; break
      case 'ArrowDown': this.direction = 'down'; break
      case 'ArrowLeft': this.direction = 'left'; break
      case 'ArrowRight': this.direction = 'right'; break
    }
    this.updatePacmanRotation()
  }

  movePacman() {
    switch(this.direction) {
      case 'up':
        this.pacmanPosition.y = (this.pacmanPosition.y - 10 + this.boardHeightValue) % this.boardHeightValue
        break
      case 'down':
        this.pacmanPosition.y = (this.pacmanPosition.y + 10) % this.boardHeightValue
        break
      case 'left':
        this.pacmanPosition.x = (this.pacmanPosition.x - 10 + this.boardWidthValue) % this.boardWidthValue
        break
      case 'right':
        this.pacmanPosition.x = (this.pacmanPosition.x + 10) % this.boardWidthValue
        break
    }
    this.updatePacmanPosition()
  }

  moveGhosts() {
    this.ghostPositions.forEach((ghostPos, index) => {
      // Cambiar dirección aleatoriamente
      if (Math.random() < 0.05) {
        ghostPos.direction = ['up', 'down', 'left', 'right'][Math.floor(Math.random() * 4)]
      }

      switch(ghostPos.direction) {
        case 'up':
          ghostPos.y = (ghostPos.y - 5 + this.boardHeightValue) % this.boardHeightValue
          break
        case 'down':
          ghostPos.y = (ghostPos.y + 5) % this.boardHeightValue
          break
        case 'left':
          ghostPos.x = (ghostPos.x - 5 + this.boardWidthValue) % this.boardWidthValue
          break
        case 'right':
          ghostPos.x = (ghostPos.x + 5) % this.boardWidthValue
          break
      }

      this.updateGhostPosition(index, ghostPos)
    })
  }

  updatePacmanPosition() {
    this.pacmanTarget.style.left = `${this.pacmanPosition.x}px`
    this.pacmanTarget.style.top = `${this.pacmanPosition.y}px`
  }

  updateGhostPosition(index, position) {
    this.ghostTargets[index].style.left = `${position.x}px`
    this.ghostTargets[index].style.top = `${position.y}px`
  }

  updatePacmanRotation() {
    let rotation = this.direction === 'up' ? -90 : 
                   this.direction === 'down' ? 90 : 
                   this.direction === 'left' ? 180 : 0
    this.pacmanTarget.style.transform = `rotate(${rotation}deg)`
  }

  animatePacmanMouth() {
    this.mouthOpen = !this.mouthOpen
    if (this.mouthOpen) {
      this.pacmanTarget.style.clipPath = 'polygon(100% 0, 50% 50%, 100% 100%, 0 100%, 0 0)'
    } else {
      this.pacmanTarget.style.clipPath = 'polygon(100% 35%, 50% 50%, 100% 65%, 0 65%, 0 35%)'
    }
  }

  checkCollisions() {
    // Check pellet collisions
    this.pelletTargets.forEach(pellet => {
      const pelletRect = pellet.getBoundingClientRect()
      const pelletPos = { x: pelletRect.left, y: pelletRect.top }
      if (this.isColliding(this.pacmanPosition, pelletPos)) {
        this.eatPellet(pellet)
      }
    })

    // Check ghost collisions
    this.ghostTargets.forEach((ghost, index) => {
      if (this.isColliding(this.pacmanPosition, this.ghostPositions[index])) {
        this.eatGhost(ghost)
      }
    })
  }

  isColliding(pos1, pos2) {
    return Math.abs(pos1.x - pos2.x) < 20 && Math.abs(pos1.y - pos2.y) < 20
  }

  eatPellet(pellet) {
    pellet.remove()
    this.score += 10
    this.updateScore()
    if (this.pelletTargets.length === 0) {
      this.winGame()
    }
  }

  eatGhost(ghost) {
    ghost.classList.add('eaten')
    setTimeout(() => {
      ghost.style.display = 'none'
    }, 500) // Hide ghost after 500ms animation
    this.score += 200
    this.updateScore()
    // Respawn ghost after 5 seconds
    setTimeout(() => this.respawnGhost(ghost), 5000)
  }

  respawnGhost(ghost) {
    const index = this.ghostTargets.indexOf(ghost)
    this.ghostPositions[index] = {
      x: Math.floor(Math.random() * this.boardWidthValue),
      y: Math.floor(Math.random() * this.boardHeightValue),
      direction: ['up', 'down', 'left', 'right'][Math.floor(Math.random() * 4)]
    }
    ghost.classList.remove('eaten')
    ghost.style.display = ''
    this.updateGhostPosition(index, this.ghostPositions[index])
  }

  respawnGhosts() {
    this.ghostTargets.forEach((ghost, index) => {
      this.ghostPositions[index] = {
        x: (index + 1) * 100,
        y: (index + 1) * 100,
        direction: ['up', 'down', 'left', 'right'][Math.floor(Math.random() * 4)]
      }
      this.updateGhostPosition(index, this.ghostPositions[index])
    })
  }

  respawnPellets() {
    const pelletsContainer = this.element.querySelector('.pellets-container')
    pelletsContainer.innerHTML = ''
    for (let i = 0; i < 20; i++) {
      const pellet = document.createElement('div')
      pellet.className = 'absolute w-2 h-2 bg-yellow-200 rounded-full shadow-[0_0_5px_#fff200] pellet'
      pellet.dataset.pacmanTarget = 'pellet'
      pellet.style.left = `${20 + (i % 5) * 90}px`
      pellet.style.top = `${20 + Math.floor(i / 5) * 90}px`
      pelletsContainer.appendChild(pellet)
    }
  }

  updateScore() {
    this.scoreTarget.textContent = `Score: ${this.score}`
  }

  winGame() {
    clearInterval(this.moveInterval)
    this.gameIsOver = true
    this.gameOverTarget.textContent = "You Win! Press Enter to restart."
    this.gameOverTarget.classList.remove('hidden')
  }

  endGame() {
    clearInterval(this.moveInterval)
    this.gameIsOver = true
    this.gameOverTarget.textContent = "Game Over! Press Enter to restart."
    this.gameOverTarget.classList.remove('hidden')
  }

  restartGame() {
    this.pacmanPosition = { x: 0, y: 0 }
    this.direction = 'right'
    this.updatePacmanPosition()
    this.updatePacmanRotation()
    this.respawnPellets()
    this.startGame()
  }
}