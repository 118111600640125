import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["alerta"] 
  connect() {
  }

  close() {

      this.alertaTarget.classList.add('translate-x-full')
      this.alertaTarget.classList.add('opacity-0')
      this.element.remove()
  }
}
