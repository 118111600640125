import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { url: String }
  
    connect() {
      this.element.addEventListener('click', this.redirectToSignUp.bind(this))
    }
  
    disconnect() {
      this.element.removeEventListener('click', this.redirectToSignUp.bind(this))
    }
  
    redirectToSignUp() {
      window.location.href = this.urlValue
    }
}
