import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['modal', 'acceptButton', 'rejectButton']

  connect() {
    if (localStorage.getItem('cookie_consent_accepted') === 'true') {
      this.hideModalImmediately();
    } else {
      this.showModal();
    }
    this.showAnalyticsAndSearch();
  }

  acceptButtonClicked() {
    localStorage.setItem('cookie_consent_accepted', 'true');
    this.hideModalImmediately();
    this.showAnalyticsAndSearch();
  }

  rejectButtonClicked() {
    localStorage.setItem('cookie_consent_accepted', 'false');
    this.hideModalImmediately();
  }

  showModal() {
    this.modalTarget.style.display = 'flex';
  }

  hideModalImmediately() {
    this.modalTarget.style.display = 'none';
  }

  showAnalyticsAndSearch() {
    if (localStorage.getItem('cookie_consent_accepted') === 'true' && !window.googleAnalyticsLoaded) {
      window.googleAnalyticsLoaded = true;
      const analyticsScript = document.createElement('script');
      analyticsScript.async = true;
      analyticsScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-8F6TS2YZZJ';
      analyticsScript.onload = function() {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'G-8F6TS2YZZJ');
      };
      document.head.appendChild(analyticsScript);
    }
  }
}