import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["saveToolButton", "message", "alerta", "sidebartool", "sidebar", "contenido"]
  static toolId;
  connect() {
    // ...
   
  }

  open(event){

    const clickedButton = event.currentTarget;
    this.toolId =    clickedButton.dataset.saveToolToolId; // Convert to number
    const toolIndex = this.sidebarTarget.dataset.saveToolToolIndex; // Convert to number
    console.log("Clicked tool IDxxxx:", this.toolId);
    this.contenidoTarget.classList.remove("hidden");
  }

  close(){
    console.log("Close entro");
    this.contenidoTarget.classList.add("hidden");
  }

  deleteToolia(){
    alert("Toolia" + this.toolId)
  }

  saveTool(event) {
    // ...
    
    const toolId = this.element.dataset.id;
    console.log("toolId: " +toolId)
    const isSaved = this.element.dataset.saved === "true";
    console.log("isSaved: " +isSaved)

  
      //this.saveToolButtonTarget.classList.remove("grayscale-on-hover"); // Elimina la clase grayscale-on-hover
   
      // Si la herramienta no está guardada, procede a guardarla
      fetch(`/saved_tools?tool_id=${toolId}`, { method: "POST", headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }})
      .then((response) => {
        if (response.ok) {
          this.element.dataset.saved = true;
          this.saveToolButtonTarget.classList.add("saved");
          this.saveToolButtonTarget.classList.remove("grayscale-on-hover");
          response.json()
          .then(data => {
            console.dir(data);
            const message = data.message;
            alert(message);
          });
          // Procesa la respuesta JSON exitosa
          console.dir(response);
        } else {
          // Procesa la respuesta JSON de error
          response.json()
            .then(data => {
              console.dir(data);
              const message = data.error;
              alert(message);
            });
        }
      });
      
    
  }

  deleteTool(event) 
  {

    // Elimina la herramienta guardada
    fetch(`/saved_tools/${this.toolId}`, { method: "DELETE", headers: {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }})
    .then((response) => {
      if (response.ok) {
        response.json()
        .then(data => {
          console.dir(data);
          const message = data.message;
          alert(message);
          location.reload();
        });
        // Procesa la respuesta JSON exitosa
        console.dir(response);
      } else {
        // Procesa la respuesta JSON de error
        response.json()
          .then(data => {
            console.dir(data);
            const message = data.error;
            alert(message);
          });
      }
    });
  }
}
