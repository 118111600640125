// app/javascript/controllers/subscription_controller.js

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "price", "paypalbtn", "cycle", "monthlyButton", "yearlyButton", "paypalScript", "planCard", "checkmark"]

  
  connect() {
    this.selectedPlan = "pro"; // Default plan
    this.selectedCycle = "monthly"; // Default cycle
    this.updatePrices();
    this.updateButtonStyles();
  }

  selectPlan(event) {
    // Remove selection from all cards
    this.planCardTargets.forEach(card => {
      card.classList.remove('border-purple-600')
      card.querySelector('[data-subscription-target="checkmark"]').classList.remove('opacity-100')
    })

    // Add selection to clicked card
    const card = event.currentTarget
    card.classList.add('border-purple-600')
    card.querySelector('[data-subscription-target="checkmark"]').classList.add('opacity-100')

    this.selectedPlan = card.dataset.plan
    this.updatePrices()
  }

  selectMonthly() {
    this.selectedCycle = "monthly";
    this.updatePrices();
    this.updateButtonStyles();
  }

  selectYearly() {
    this.selectedCycle = "yearly";
    this.updatePrices();
    this.updateButtonStyles();
  }

 

  updatePrices() {
    this.priceTargets.forEach(price => {
      const monthlyPrice = parseFloat(price.dataset.monthlyPrice);
      const yearlyPrice = parseFloat(price.dataset.yearlyPrice);
      price.textContent = this.selectedCycle === "monthly" ? monthlyPrice : yearlyPrice;
    });

    this.cycleTargets.forEach(cycle => {
      cycle.textContent = this.selectedCycle === "monthly" ? "/mes" : "/año";
    });
  }

  updateButtonStyles() {
    this.monthlyButtonTarget.classList.toggle("bg-purple-600", this.selectedCycle === "monthly");
    this.monthlyButtonTarget.classList.toggle("text-white", this.selectedCycle === "monthly");
    this.monthlyButtonTarget.classList.toggle("text-slate-300", this.selectedCycle !== "monthly");

    this.yearlyButtonTarget.classList.toggle("bg-purple-600", this.selectedCycle === "yearly");
    this.yearlyButtonTarget.classList.toggle("text-white", this.selectedCycle === "yearly");
    this.yearlyButtonTarget.classList.toggle("text-slate-300", this.selectedCycle !== "yearly");
  }

  openModal() {
    this.modalTarget.classList.remove("hidden"); // Muestra el modal
    this.renderPayPalButton(); // Llama a la función que renderiza el botón de PayPal
  }

  closeModal() {
    this.paypalbtnTarget.classList.add("hidden"); 
    this.containerTarget.innerHTML = ""; // Limpia el contenedor al cerrar
  }

  async renderPayPalButton() {
    this.paypalbtnTarget.classList.remove("hidden"); 
    const clientId = this.element.dataset.subscriptionClientIdValue;
    const monthlyPlanId = this.element.dataset.subscriptionMonthlyPlanIdValue;
    const yearlyPlanId = this.element.dataset.subscriptionYearlyPlanIdValue;
    const planId = this.selectedCycle === "monthly" ? monthlyPlanId : yearlyPlanId;

    this.containerTarget.innerHTML = `
      <div id="paypal-button-container-${planId}"></div>
    `;

    // Remove any existing PayPal script
    if (this.paypalScriptTarget.firstChild) {
      this.paypalScriptTarget.removeChild(this.paypalScriptTarget.firstChild);
    }

    try {
      // Load the PayPal SDK script asynchronously
      await this.loadPayPalScript(clientId);

      // Render the PayPal button
      paypal.Buttons({
        style: {
          shape: 'pill',
          color: 'gold',
          layout: 'vertical',
          label: 'subscribe'
        },
        createSubscription: function(data, actions) {
          console.log("Creating subscription with plan ID:", planId);
          return actions.subscription.create({
            plan_id: planId
          });
        },
        onApprove: function(data, actions) {
           // You can add optional success message for the subscriber here I-JGX2DAVPRBPY
           console.log("Suscripción aprobada. ID:", data.subscriptionID);
           window.location.href = `/subscriptions/${data.subscriptionID}/success`;
        }
      }).render(`#paypal-button-container-${planId}`); // Renders the PayPal button
    } catch (error) {
      console.error("Error loading PayPal script:", error);
    }
  }

  loadPayPalScript(clientId) {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&vault=true&intent=subscription`;
      script.dataset.sdkIntegrationSource = "button-factory";
      script.onload = resolve;
      script.onerror = reject;
      this.paypalScriptTarget.appendChild(script);
    });
  }
}