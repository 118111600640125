// app/javascript/channels/chatia_channel.js
import consumer from "./consumer"
import Prism from 'prismjs';


const chatiaChannel = consumer.subscriptions.create("ChatiaChannel", {
  connected() {
    console.log("Connected to ChatiaChannel");
  },

  disconnected() {
    console.log("Disconnected from ChatiaChannel");
  },

  received(data) {
    const messagesContainer = document.getElementById('chat-messages');
    let messageHtml;

    if (data.type === 'user_message') {
      messageHtml = this.formatUserMessage(data.author, data.message);
    } else if (data.type === 'ai_response') {
      messageHtml = this.formatAIResponse(data.message);
    }

    if (messageHtml && messagesContainer) {
      const messageElement = document.createElement('div');
      messageElement.innerHTML = messageHtml;
      messagesContainer.appendChild(messageElement);
      messagesContainer.scrollTop = messagesContainer.scrollHeight;

       // Resaltar sintaxis de código
       Prism.highlightAll();
    }
  },

  formatUserMessage(author, message) {
    return `
      <div class="mb-2">
        <div class="message-box">
        <div class="chat-message my-1">
          <div class="flex items-end justify-end " >
            <div class="order-1 items-end flex flex-col space-y-2 text-xs max-w-lg mx-2">
              <div>
                <span
                    class="
                      rounded-bl-none
                      bg-fondo-gradiente bg-slate-900 text-slate-200
                      px-4 py-3 rounded-lg inline-block">
                    <div class="flex">
                    <h6 class="text-sky-400 capitalize"> ${this.escapeHtml(author)}</h6>
                      <small class="time mx-2 small">aqui la fecha></small>
                         </div>
                         ${this.escapeHtml(message)}
                         </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>`;
  },

  formatAIResponse(message) {
    let formattedHtml = message
      .replace(/\*\*(.*?)\*\*/g, '<strong class="text-bold text-md">$1</strong>')
      .replace(/\*\s*(.*?)\n/g, '<li>$1</li>')
      .replace(/\[PARAGRAPH\]/g, '</p><p class="mb-4 text-bold text-base text-slate-600/80">')
      .replace(/\\n/g, '\n')
      .replace(/\[CODE\](\w+)\n([\s\S]*?)\[\/CODE\]/g, (_, lang, code) => `
        <div class="code-block relative bg-fondo-gradiente bg-slate-900 rounded-lg overflow-hidden">
          <div class="code-header bg-fondo-gradiente bg-slate-900 p-2 flex justify-between items-center rounded-t-lg">
            <span class="font-bold text-sky-500">code:[${lang}]</span>
            <button class="copy-button text-slate-300 hover:text-slate-100" >Copy</button>
          </div>
          <pre class="p-4 text-xs md:text-base overflow-x-auto">
            <code class="text-slate-200 language-${lang}">
              ${this.escapeHtml(code.trim())}
            </code>
          </pre>
        </div>
      `)
      .replace(/\[LIST_ITEM\]([\s\S]*?)\[\/LIST_ITEM\]/g, '<li class="">$1</li>')
      .replace(/`([^`]+)`/g, '<code class=" text-sky-700 px-1 py-0.5 rounded">$1</code>');
  
    // Envuelve los elementos de la lista en un contenedor <ul>
    formattedHtml = `<div class="ai-response max-w-full py-3 pl-4 pr-8 rounded-lg rounded-tl-none overflow-ellipsis overflow-hidden font-normal relative transition-colors text-base bg-[#f0f0f3] text-slate-12">${formattedHtml}</div>`;
  
    return `
      <div class="mb-2 text-slate-600">
        <span class="font-bold text-green-500">IA:</span> ${formattedHtml}
      </div>`;
  },

  escapeHtml(unsafe) {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }
});

document.addEventListener('DOMContentLoaded', () => {
  const chatContainer = document.getElementById('chat-container');
  const form = document.getElementById('chat-form');
  const input = document.getElementById('chat-input');
  const modelOptions = document.querySelectorAll('#model-selection > div');
  const selectedModelInput = document.getElementById('selected-model');

  if (chatContainer && form && input && modelOptions.length && selectedModelInput) {
    const currentAuthorId = chatContainer.dataset.currentAuthorId;

    modelOptions.forEach(option => {
      option.addEventListener('click', () => {
        modelOptions.forEach(opt => opt.classList.remove('bg-slate-900','bg-fondo-gradiente', 'border-slate-200', 'text-slate-200'));
        option.classList.add('bg-slate-900','bg-fondo-gradiente', 'border-slate-200', 'text-slate-200');
        selectedModelInput.value = option.dataset.model;
      });
    });

    const sendMessage = () => {
      const message = input.value.trim();
      const model = selectedModelInput.value;
      if (message.length > 0) {
        chatiaChannel.perform('receive', { message: message, author: currentAuthorId, model: model });
        input.value = '';
      }
    };

    form.addEventListener('submit', (e) => {
      e.preventDefault();
      sendMessage();
    });

    input.addEventListener('keypress', (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        sendMessage();
      }
    });

    // Inicializar con Google Gemini seleccionado
    document.querySelector('[data-model="google_gemini"]').click();
  } else {
    console.error('Uno o más elementos requeridos no fueron encontrados');
  }
});

// Mantener el código existente para Prism.js
document.addEventListener('DOMContentLoaded', () => {
  if (typeof Prism !== 'undefined') {
    Prism.highlightAll();
  }
});