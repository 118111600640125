import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["footer"]
  static values = { id: Number }

  connect() {
    // Ya no necesitamos inicializar nada aquí
  }

  showprofile(event) {
    const url_web = 'https://iacomunia.com'
    const nombre_usuario = this.data.get("myValue")
    console.log("Nombre de herramienta:" + nombre_usuario)
    Turbo.visit(url_web + nombre_usuario)
  }

  showFooter() {
    const span = this.footerTarget.querySelector('span')
    span.classList.remove('opacity-0')
    span.classList.add('opacity-100')
  }

  hideFooter() {
    const span = this.footerTarget.querySelector('span')
    span.classList.remove('opacity-100')
    span.classList.add('opacity-0')
  }
}