import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["topsimilar"]
  connect() {
    console.log("Ingreso modale delete")
  }

  open(event){
    this.topsimilarTarget.classList.toggle("hidden");
  }



}
