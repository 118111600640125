import consumer from "./consumer"

let reseteoFuncion;
let timer = 0;

consumer.subscriptions.create("AppearanceChannel", {

  initialized() {
    console.log("se inicializo - Apariencia Channel");
  },

  connected() {
     // Called when the subscription is ready for use on the server
     console.log("Connected Chanel apariencia avatar status ");
     reseteoFuncion = () => this.resetTimer(this.uninstall);
     this.install();
     window.addEventListener("turbo:load", () => this.resetTimer());
  },

    appear(){
      console.log("APPEAR")
      this.perform("appear", { appearing_on: document.getElementById("appearance_channel").data("appearing-on")});
    },

  disconnected() {
    console.log("DISCConnected");
    this.uninstall();
  },


  rejected() {
    console.log("Rejected");
    this.uninstall();
  },


  received(data) {

    
  },


  online() {
    console.log("online");
    this.perform("online");
  },


  away() {
    console.log("away");
    this.perform("away");
  },


  offline() {
    console.log("offline");
    this.perform("offline");
  },



  uninstall() {
    const shouldRun = document.getElementById("appearance_channel");
    if (!shouldRun) {
      clearTimeout(timer);
      this.perform("offline");
      console.log("removio appearance_channel turbo_tag :" +shouldRun)
    }

  },

  install() {
    console.log("Install ingreso");
    window.removeEventListener("load", reseteoFuncion);
    window.removeEventListener("DOMContentLoaded", reseteoFuncion);
    window.removeEventListener("click", reseteoFuncion);
    window.removeEventListener("keydown", reseteoFuncion);

    window.addEventListener("load", reseteoFuncion);
    window.addEventListener("DOMContentLoaded", reseteoFuncion);
    window.addEventListener("click", reseteoFuncion);
    window.addEventListener("keydown", reseteoFuncion);
    this.resetTimer();
  },

  resetTimer() {
    this.uninstall();
    const shouldRun = document.getElementById("appearance_channel");

    if (!!shouldRun) {
      this.online();
      clearTimeout(timer);
      const timeInSeconds = 5;
      const milliseconds = 3000;
      const timeInMinutes = timeInSeconds * 60 * milliseconds;
      const numberOfMinutes = 5;
      const timeInMilliseconds = timeInMinutes * numberOfMinutes;
      // const timeInMilliseconds = timeInSeconds * milliseconds;
      timer = setTimeout(this.away.bind(this), timeInMilliseconds);
    }
  },
});
