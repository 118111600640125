// app/javascript/controllers/chat_controller.js
import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ["messages", "input", "form"]

  connect() {
    this.channel = consumer.subscriptions.create("ChatiaChannel", {
      connected: this.cableConnected.bind(this),
      disconnected: this.cableDisconnected.bind(this),
      received: this.cableReceived.bind(this)
    })
  }

  cableConnected() {
    console.log("Connected to ChatiaChannel")
  }

  cableDisconnected() {
    console.log("Disconnected from ChatiaChannel")
  }

  cableReceived(data) {
    if (data.type === 'user_message') {
      this.appendMessage(data.author, data.message, 'user')
    } else if (data.type === 'ai_response') {
      this.formatAndDisplayResponse(data.message)
    }
  }

  appendMessage(author, message, type) {
    const messageElement = document.createElement("div")
    messageElement.classList.add("mb-2", type === 'user' ? "text-blue-600" : "text-green-600")
    messageElement.innerHTML = `<span class="font-bold">${author}:</span> ${message}`
    this.messagesTarget.appendChild(messageElement)
    this.scrollToBottom()
  }

  formatAndDisplayResponse(message) {
    let formattedHtml = message
      .replace(/\[PARAGRAPH\]/g, '</p><p class="mb-4">')
      .replace(/\[CODE\](\w+)\n([\s\S]*?)\[\/CODE\]/g, (_, lang, code) => `
        <pre class="bg-gray-100 p-4 rounded-md overflow-x-auto">
          <code class="language-${lang}">${this.escapeHtml(code.trim())}</code>
        </pre>
      `)
      .replace(/\[LIST_ITEM\]([\s\S]*?)\[\/LIST_ITEM\]/g, '<li class="ml-4">$1</li>')

    formattedHtml = `<p class="mb-4">${formattedHtml}</p>`
    
    const aiMessageElement = document.createElement("div")
    aiMessageElement.classList.add("mb-2", "text-green-600")
    aiMessageElement.innerHTML = `<span class="font-bold">IA:</span> ${formattedHtml}`
    this.messagesTarget.appendChild(aiMessageElement)
    
    this.scrollToBottom()
    
    // Si estás usando una librería de resaltado de sintaxis como Prism.js
    // Prism.highlightAll()
  }

  escapeHtml(unsafe) {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }

  submit(event) {
    event.preventDefault()
    const message = this.inputTarget.value.trim()
    if (message.length > 0) {
      this.channel.perform('receive', { message: message, author: this.currentAuthorId })
      this.appendMessage(this.currentAuthorId, message, 'user')
      this.inputTarget.value = ''
    }
  }

  scrollToBottom() {
    this.messagesTarget.scrollTop = this.messagesTarget.scrollHeight
  }

  get currentAuthorId() {
    return this.element.dataset.currentAuthorId
  }
}