import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]

  connect() {
    document.addEventListener('click', this.closeBackground.bind(this))
  }

  disconnect() {
    document.removeEventListener('click', this.closeBackground.bind(this))
  }

  closeBackground(e) {
    if (e.target.classList.contains('editor-background')) {
      this.close()
    }
  }

  open() {
    this.formTarget.classList.remove('hidden')
    document.body.classList.add('overflow-hidden')
  }

  close() {
    this.formTarget.classList.add('hidden')
    document.body.classList.remove('overflow-hidden')
  }
}