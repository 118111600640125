import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    console.log("Ingreso conect reset")
  }

  reset() {
    console.log("Ingreso reset")
    this.element.reset();
    
  }
}