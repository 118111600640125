// app/javascript/controllers/avatar_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["avatar"]

  connect() {
    this.avatarTarget.hidden = false
  }

 


  previewImage(event) {
    const inputElement = event.target;
    const selectedFile = event.target.files[0];

    // Validar si se ha seleccionado un archivo
    if (selectedFile) {
      // Validar el tamaño del archivo (500 KB)
      if (selectedFile.size > 500 * 1024) { // 500 KB en bytes
        alert("El archivo debe ser menor de 500 KB. SELECCIONA OTRA IMAGEN mas LIGERA");
        inputElement.value = ""; // Limpiar el campo de entrada
        this.avatarTarget.hidden = true; // Ocultar la imagen previa
        return; // Salir de la función
      }

      // Si el archivo es válido, mostrar la vista previa
      const reader = new FileReader();
      reader.onload = (e) => {
        this.avatarTarget.src = e.target.result;
        this.avatarTarget.hidden = false;
      };
      reader.readAsDataURL(selectedFile);
    }
  }

}