import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "canvas" ]

  connect() {
    this.canvas = this.canvasTarget
    this.ctx = this.canvas.getContext('2d')
    this.setupGame()
    this.gameLoop()
  }

  setupGame() {
    this.player = { x: 50, y: 200, width: 30, height: 30, dy: 0, jumpForce: 10, grounded: false }
    this.platforms = [
      { x: 0, y: 350, width: 800, height: 50 },
      { x: 300, y: 300, width: 100, height: 20 },
      { x: 500, y: 200, width: 100, height: 20 },
    ]
    this.keys = {}
    this.cameraOffset = 0

    this.bindKeys()
  }

  bindKeys() {
    document.addEventListener('keydown', (e) => this.keys[e.code] = true)
    document.addEventListener('keyup', (e) => this.keys[e.code] = false)
  }

  gameLoop() {
    this.update()
    this.render()
    requestAnimationFrame(() => this.gameLoop())
  }

  update() {
    // Apply gravity
    this.player.dy += 0.5
    this.player.y += this.player.dy

    // Check collisions
    this.player.grounded = false
    for (let platform of this.platforms) {
      if (
        this.player.x < platform.x - this.cameraOffset + platform.width &&
        this.player.x + this.player.width > platform.x - this.cameraOffset &&
        this.player.y + this.player.height > platform.y &&
        this.player.y + this.player.height < platform.y + platform.height
      ) {
        this.player.grounded = true
        this.player.y = platform.y - this.player.height
        this.player.dy = 0
      }
    }

    // Movement
    if (this.keys.ArrowLeft) this.player.x -= 5
    if (this.keys.ArrowRight) {
      this.player.x += 5
      this.cameraOffset += 5  // Move the camera
    }
    if (this.keys.ArrowUp && this.player.grounded) {
      this.player.dy = -this.player.jumpForce
    }

    // Generate new platforms
    if (this.cameraOffset > this.platforms[this.platforms.length - 1].x - 800) {
      this.platforms.push({
        x: this.platforms[this.platforms.length - 1].x + Math.random() * 200 + 200,
        y: Math.random() * 200 + 150,
        width: 100,
        height: 20
      })
    }

    // Remove off-screen platforms
    this.platforms = this.platforms.filter(p => p.x - this.cameraOffset > -200)

    // Keep player in bounds
    this.player.x = Math.max(0, Math.min(this.player.x, this.canvas.width - this.player.width))
  }

  render() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

    // Draw player
    this.ctx.fillStyle = 'red'
    this.ctx.fillRect(this.player.x, this.player.y, this.player.width, this.player.height)

    // Draw platforms
    this.ctx.fillStyle = 'green'
    for (let platform of this.platforms) {
      this.ctx.fillRect(platform.x - this.cameraOffset, platform.y, platform.width, platform.height)
    }
  }
}