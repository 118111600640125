// app/javascript/controllers/filter_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "button", "dropdown" ]

  connect() {
    this.closeDropdown = this.closeDropdown.bind(this)
    document.addEventListener('click', this.closeDropdown)
  }

  disconnect() {
    document.removeEventListener('click', this.closeDropdown)
  }

  toggleDropdown(event) {
    event.stopPropagation()
    this.dropdownTarget.classList.toggle('hidden')
  }

  closeDropdown(event) {
    if (!this.element.contains(event.target)) {
      this.dropdownTarget.classList.add('hidden')
    }
  }

  select(event) {
    event.preventDefault()
    const filter = event.currentTarget.dataset.filter
    const text = event.currentTarget.textContent.trim()
    
    // Actualizar el texto del botón
    this.buttonTarget.querySelector('span').textContent = text
    
    // Ocultar el dropdown
    this.dropdownTarget.classList.add('hidden')
    
    // Actualizar el input hidden y enviar el formulario
    const form = this.element.querySelector('form')
    const input = form.querySelector('input[name="filter"]')
    input.value = filter
    
    // Agregar un indicador de carga
    this.buttonTarget.classList.add('opacity-50', 'cursor-wait')
    
    // Enviar el formulario
    form.requestSubmit()
  }
}