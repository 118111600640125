// app/javascript/controllers/vertical_carousel_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "image", "modal", "modalImage"]

  connect() {
    this.updatePositions()
  }

  centerImage(event) {
    const clickedIndex = parseInt(event.currentTarget.dataset.index)
    this.centerIndex = clickedIndex
    this.updatePositions()
  }

  openModal(event) {
    // Obtener la URL de la imagen del elemento clickeado
    const imageUrl = event.currentTarget.dataset.imageUrl;
    
    // Establecer la URL de la imagen en el modal
    this.modalImageTarget.src = imageUrl;

    // Mostrar el modal
    this.modalTarget.classList.remove("hidden");

    // Código existente para cargar el contenido del modal
    const imageId = event.currentTarget.dataset.imageId;
    fetch(`/ai_images/${imageId}/modal`)
      .then(response => response.text())
      .then(html => {
        this.modalTarget.innerHTML = html;
      });
  }

  closeModal() {
    this.modalTarget.classList.add('hidden')
    this.modalTarget.innerHTML = ''
  }


  updatePositions() {
    const containerHeight = this.containerTarget.offsetHeight
    const imageHeight = this.imageTargets[0].offsetHeight
    const visibleImages = Math.floor(containerHeight / imageHeight)
    const centerPosition = Math.floor(visibleImages / 2)
    const imageElement = document.querySelector('#image-vertical');
    this.imageTargets.forEach((image, index) => {
      let position = index - this.centerIndex + centerPosition
      if (position < 0) position += this.imageTargets.length
      if (position >= this.imageTargets.length) position -= this.imageTargets.length
      console.log("Aqui img: " + image)
      image.style.transform = `translateY(${position * 100}%)`
      image.style.opacity = position === centerPosition ? "1" : "1"
      image.style.scale = position === centerPosition ? "1" : "1"
      image.style.zIndex = this.imageTargets.length - Math.abs(position - centerPosition)
      imageElement.style.aspectRatio = 1/1
      imageElement.style.objectFit = 'cover' 
    })
  }
}