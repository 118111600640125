import { Controller } from "@hotwired/stimulus"

export default class Slides extends Controller {
    static targets = [ "prevu", "nextio", "trackku" ];
    static carousel;
    static width;
    static trackk;
    static prev;
    static next;
    static index = 0;

  connect() {
    this.initialize();
   
  }

  initialize(){
    console.log("Ingreso en inicializacion SlidesControllers")
    Slides.prev = document.getElementById("prev_category");
    Slides.carousel = document.getElementById("carousel-containero");
    Slides.trackk = document.getElementById("trackk");
    Slides.next = document.getElementById("next_category"); 
    Slides.width = Slides.carousel.offsetWidth;      
  }

  // ... Tu código existente ...



  handleTouchMove(event) {
    if (!this.isSwiping) return; // Ignorar si no se está deslizando

    const currentTouchX = event.touches[0].clientX;
    const deltaX = currentTouchX - this.initialTouchX; // Calcular la distancia de deslizamiento

    // Prevenir el comportamiento de desplazamiento predeterminado durante el deslizamiento
    event.preventDefault();

    // Implementar la lógica de deslizamiento según deltaX (umbral o dirección)
    if (deltaX > 50) { // Ejemplo: Deslizar hacia la derecha (el umbral se puede ajustar)
      this.prev();
    } else if (deltaX < -50) { // Ejemplo: Deslizar hacia la izquierda (el umbral se puede ajustar)
      this.next();
    }

    this.isSwiping = false; // Desactivar la bandera de deslizamiento
  }



  next(e) {

      if(Slides.carousel)
        {

          window.addEventListener("resize", function () 
          {
            Slides.width = Slides.carousel.offsetWidth;
            console.log("ResizewNEXT: "+ Slides.width)
          });

            e.preventDefault();
            Slides.index = Slides.index + 1;
            Slides.prev.classList.add("show");
            Slides.trackk.style.transform = "translateX(" + Slides.index * -Slides.width+ "px)";
            if (Slides.index >= Slides.trackk.offsetWidth / Slides.width ) 
            {
              console.log("track "+ Slides.trackk.offsetWidth + " index:: " + Slides.index + "width:: " + Slides.width )
              // next.classList.add("hide");
              Slides.next.classList.add("hide");
            }
        }
  }

  prev() {

    if(Slides.carousel)
    {



      window.addEventListener("resize", function () 
          {
            Slides.width = Slides.carousel.offsetWidth;
            console.log("ResizewPREV: "+ Slides.width)
          });
        Slides.index = Slides.index - 1;
        console.log("Slides menos "+ Slides.index)
        Slides.next.classList.remove("hide");
        if (Slides.index === 0) {
            Slides.prev.classList.remove("show");
        }
        Slides.trackk.style.transform = "translateX(" + Slides.index * -Slides.width + "px)";
      
      }
}

}
