import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    
  connect() {
    console.log("Ingreso hello")
  }

  greet() {
    console.log("click")
  }
}
