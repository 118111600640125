import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "canvas", "score", "startButton", "pauseButton", "resetButton" ]
  static values = {
    gridSize: Number,
    tileCount: Number
  }

  connect() {
    this.snake = [{ x: 10, y: 10 }]
    this.food = { x: 15, y: 15 }
    this.dx = 0
    this.dy = 0
    this.score = 0
    this.ctx = this.canvasTarget.getContext('2d')
    this.isRunning = false
    this.isPaused = false
    
    this.drawGame = this.drawGame.bind(this)
    this.changeDirection = this.changeDirection.bind(this)
    
    // Agregar listener para las teclas de flecha
    document.addEventListener('keydown', this.changeDirection)
    
    // Dibujar el estado inicial del juego
    this.clearCanvas()
    this.drawSnake()
    this.drawFood()
    this.updateScore()
  }

  disconnect() {
    this.stopGame()
    document.removeEventListener('keydown', this.changeDirection)
  }

  startGame() {
    if (!this.isRunning) {
      this.isRunning = true
      this.isPaused = false
      this.gameInterval = setInterval(this.drawGame, 100)
      this.startButtonTarget.disabled = true
      this.pauseButtonTarget.disabled = false
    }
  }

  pauseGame() {
    if (this.isRunning && !this.isPaused) {
      this.isPaused = true
      clearInterval(this.gameInterval)
      this.pauseButtonTarget.textContent = "Reanudar"
    } else if (this.isRunning && this.isPaused) {
      this.isPaused = false
      this.gameInterval = setInterval(this.drawGame, 100)
      this.pauseButtonTarget.textContent = "Pausar"
    }
  }

  resetGame() {
    this.stopGame()
    this.snake = [{ x: 10, y: 10 }]
    this.food = { x: 15, y: 15 }
    this.dx = 0
    this.dy = 0
    this.score = 0
    this.clearCanvas()
    this.drawSnake()
    this.drawFood()
    this.updateScore()
    this.startButtonTarget.disabled = false
    this.pauseButtonTarget.disabled = true
    this.pauseButtonTarget.textContent = "Pausar"
  }

  stopGame() {
    this.isRunning = false
    this.isPaused = false
    clearInterval(this.gameInterval)
  }

  drawGame() {
    if (this.isPaused) return
    this.clearCanvas()
    this.moveSnake()
    this.drawFood()
    this.drawSnake()
    this.checkCollision()
    this.updateScore()
  }

  clearCanvas() {
    this.ctx.fillStyle = '#1a1a2e'
    this.ctx.fillRect(0, 0, this.canvasTarget.width, this.canvasTarget.height)
  }

  drawSnake() {
    this.snake.forEach((segment) => {
      const gradient = this.ctx.createRadialGradient(
        segment.x * this.gridSizeValue + this.gridSizeValue / 2,
        segment.y * this.gridSizeValue + this.gridSizeValue / 2,
        0,
        segment.x * this.gridSizeValue + this.gridSizeValue / 2,
        segment.y * this.gridSizeValue + this.gridSizeValue / 2,
        this.gridSizeValue / 2
      )
      gradient.addColorStop(0, '#16d9e3')
      gradient.addColorStop(1, '#30c5cc')
      this.ctx.fillStyle = gradient
      this.ctx.beginPath()
      this.ctx.roundRect(segment.x * this.gridSizeValue, segment.y * this.gridSizeValue, this.gridSizeValue - 2, this.gridSizeValue - 2, 5)
      this.ctx.fill()
      this.ctx.shadowBlur = 10
      this.ctx.shadowColor = '#16d9e3'
    })
  }

  drawFood() {
    const gradient = this.ctx.createRadialGradient(
      this.food.x * this.gridSizeValue + this.gridSizeValue / 2,
      this.food.y * this.gridSizeValue + this.gridSizeValue / 2,
      0,
      this.food.x * this.gridSizeValue + this.gridSizeValue / 2,
      this.food.y * this.gridSizeValue + this.gridSizeValue / 2,
      this.gridSizeValue / 2
    )
    gradient.addColorStop(0, '#ff0080')
    gradient.addColorStop(1, '#ff00ff')
    this.ctx.fillStyle = gradient
    this.ctx.beginPath()
    this.ctx.arc(this.food.x * this.gridSizeValue + this.gridSizeValue / 2, this.food.y * this.gridSizeValue + this.gridSizeValue / 2, this.gridSizeValue / 2 - 2, 0, Math.PI * 2)
    this.ctx.fill()
    this.ctx.shadowBlur = 15
    this.ctx.shadowColor = '#ff00ff'
  }

  moveSnake() {
    const head = { x: this.snake[0].x + this.dx, y: this.snake[0].y + this.dy }
    this.snake.unshift(head)
    if (head.x === this.food.x && head.y === this.food.y) {
      this.score++
      this.generateFood()
    } else {
      this.snake.pop()
    }
  }

  generateFood() {
    this.food = {
      x: Math.floor(Math.random() * this.tileCountValue),
      y: Math.floor(Math.random() * this.tileCountValue)
    }
  }

  checkCollision() {
    const head = this.snake[0]
    if (head.x < 0 || head.x >= this.tileCountValue || head.y < 0 || head.y >= this.tileCountValue) {
      this.gameOver()
    }
    for (let i = 1; i < this.snake.length; i++) {
      if (head.x === this.snake[i].x && head.y === this.snake[i].y) {
        this.gameOver()
      }
    }
  }

  gameOver() {
    this.stopGame()
    alert(`¡Juego terminado! Tu puntuación final es: ${this.score}`)
    this.resetGame()
  }

  updateScore() {
    this.scoreTarget.textContent = `Puntuación: ${this.score}`
  }

  changeDirection(event) {
    if (!this.isRunning || this.isPaused) return

    const key = event.key || event.currentTarget.dataset.direction
    const newDirection = {
      'ArrowUp': [0, -1],
      'ArrowDown': [0, 1],
      'ArrowLeft': [-1, 0],
      'ArrowRight': [1, 0],
      'up': [0, -1],
      'down': [0, 1],
      'left': [-1, 0],
      'right': [1, 0]
    }[key]

    if (newDirection) {
      const [newDx, newDy] = newDirection
      if ((newDx === 1 && this.dx !== -1) || (newDx === -1 && this.dx !== 1) ||
          (newDy === 1 && this.dy !== -1) || (newDy === -1 && this.dy !== 1)) {
        this.dx = newDx
        this.dy = newDy
      }
    }
  }
}