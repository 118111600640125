import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["sidebar"]
  connect() {
    console.log("Ingreso modal controller que tiene un turbo submir")

 
    this.element.addEventListener("turbo:submit-end", (event) => {
      console.log("dentro del element" + event.detail.success)
        if(event.detail.success){
          console.log("dentro del fetch" + event.detail.fetchResponse.response.url)
            Turbo.visit(event.detail.fetchResponse.response.url)
        }
        
    })
  }

  close(){
    console.log("Final");
      this.sidebarTarget.classList.remove('-translate-x-1/2')
      this.sidebarTarget.classList.remove('left-1/2')
      this.sidebarTarget.classList.add('-translate-x-full')
      this.element.remove()
  }


}
