import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["message", "notify", "avatar"]
  
  connect() {
  }

  toggle() {
    this.messageTarget.classList.toggle('hidden')
  }

  notify(){
    this.notifyTarget.classList.toggle('hidden')
  }

  avatar(){
    this.avatarTarget.classList.toggle('hidden')
  }
}
