// credits_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "count", "progressBar" ]

  connect() {
    this.updateProgressBar()
    this.updateCredits()
  }

  updateCredits() {
    fetch('/author/credits')
      .then(response => response.json())
      .then(data => {
        this.countTarget.textContent = data.credits
      })
  }

  updateProgressBar() {
    const credits = parseInt(this.countTarget.textContent)
    const percentage = (credits / 10) * 100
    this.progressBarTarget.style.width = `${percentage}%`
    
    if (credits === 0) {
      this.element.classList.add('credits-zero')
      this.progressBarTarget.classList.remove('bg-indigo-500')
      this.progressBarTarget.classList.add('bg-red-500')
      this.countTarget.classList.add('text-red-500')
    } else {
      this.element.classList.remove('credits-zero')
      this.progressBarTarget.classList.add('bg-indigo-500')
      this.progressBarTarget.classList.remove('bg-red-500')
      this.countTarget.classList.remove('text-red-500')
    }
  }
}