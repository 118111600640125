// app/javascript/controllers/heart_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["iconLike", "iconDislike"]

  connect() {
    this.applySize()
  }

  applySize() {
    const size = this.data.get("size") // Cambiar a data.get para obtener el tamaño
    if (size) {
      this.iconLikeTarget.classList.add(...size.split(' ')); // Añadir clases al icono de like
      this.iconDislikeTarget.classList.add(...size.split(' ')); // Añadir clases al icono de dislike
    }
  }
}