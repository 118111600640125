import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["image", "cfgScale", "seed", "notify", "loader", "video"]

  generate(event) {
    event.preventDefault();
    this.startLoading();

    const formData = new FormData();
    formData.append('image', this.imageTarget.files[0]);
    formData.append('cfg_scale', this.cfgScaleTarget.value);
    formData.append('seed', this.seedTarget.value);

    fetch('/ai_generative_videos/video-generator/generate', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
      body: formData
    })
    .then(response => response.json())
    .then(data => {
      this.stopLoading();
      if (data.error) {
        this.showNotification(data.error, 'error');
      } else {
        this.showVideo(data.video_url);
        this.showNotification('Video generado con éxito', 'success');
      }
    })
    .catch(error => {
      this.stopLoading();
      this.showNotification('Error al generar el video', 'error');
      console.error('Error:', error);
    });
  }

  startLoading() {
    this.loaderTarget.classList.remove('hidden');
  }

  stopLoading() {
    this.loaderTarget.classList.add('hidden');
  }

  showVideo(url) {
    this.videoTarget.classList.remove('hidden');
    this.videoTarget.querySelector('video').src = url;
  }

  showNotification(message, type) {
    this.notifyTarget.textContent = message;
    this.notifyTarget.classList.add(type === 'error' ? 'bg-red-100' : 'bg-green-100');
    this.notifyTarget.classList.remove('hidden');
  }
}