import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["lista", "grid", "fulllista", "mobilelista"]

  connect() {
  }

  openlista() {
    this.listaTarget.classList.remove('hidden')
 
    this.gridTarget.classList.add('hidden')
    this.gridTarget.classList.remove('md:block')
    this.fulllistaTarget.classList.add('hidden')
    this.mobilelistaTarget.classList.add('hidden')
  }

  openmobilelista(){
    this.listaTarget.classList.add('hidden')
    this.mobilelistaTarget.classList.remove('md:hidden')
     this.mobilelistaTarget.classList.remove('hidden')
    this.mobilelistaTarget.classList.add('block')
    this.fulllistaTarget.classList.add('hidden')
    this.gridTarget.classList.add('hidden')
  }

  openfulllista() {
    this.fulllistaTarget.classList.remove('hidden')
 
    this.listaTarget.classList.add('hidden')
    this.gridTarget.classList.add('hidden')
    this.mobilelistaTarget.classList.add('hidden')
  }

  opengrid() {
    this.listaTarget.classList.add('hidden')
    this.gridTarget.classList.remove('hidden')
   
    this.fulllistaTarget.classList.add('hidden')
    this.mobilelistaTarget.classList.add('hidden')
  }

}
