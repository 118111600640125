import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['mayoredad']
  connect() {
    console.log("Ingreso advetencia")

    window.addEventListener('load', ()=>{
           
             if (sessionStorage.getItem('mayoredad') < 1 ) {
                this.mayoredadTarget.classList.remove('hidden')
                sessionStorage.setItem('mayoredad', 1);     
               }else{
                console.log( sessionStorage.getItem('mayoredad'))
               }  
      
      });
  }

  close() {
    this.mayoredadTarget.classList.add('hidden')
  }
}
