// app/javascript/controllers/redirectimg_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String }

  connect() {
     
  }

  goToChatia(event) {
    console.log("GoTo Chatia...")
    const url_web = 'iacomunia.com'
    const url_name = this.data.get("urlValue")
    console.log("URL:"+ url_name)
    Turbo.visit(`${url_name}`)
  }

  
}