// app/javascript/controllers/subcategories_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["categorySelect", "subcategorySelect"];

  connect() {
    console.log("Entro en Subcategorires stimulus")
    this.fetchSubcategories(); // Carga las subcategorías iniciales

      // Agrega un evento de escucha para el cambio en el selector de categorías
      this.categorySelectTarget.addEventListener('change', () => {
        console.log("Entro en escucha");
        this.fetchSubcategories();
      });
  }

  fetchSubcategories() {

    // const selectedCategoryId = this.categorySelectTarget.value;
    const selectedCategoryId = this.element.querySelector("select").value;
    console.log("Categoría dos seleccionada:", selectedCategoryId);
    // Realiza una solicitud AJAX al servidor para obtener las subcategorías
    fetch(`/tools/${selectedCategoryId}/subcategories`)
      .then((response) => response.json())
      .then((subcategories) => {
        this.updateSubcategorySelect(subcategories);
      });
  }

  updateSubcategorySelect(subcategories) {
    console.log("Entro en sub categorias", JSON.stringify(subcategories));
    // Actualiza el select de subcategorías con las opciones recibidas
    const subcategorySelect = this.element.querySelector('[data-subcategories-target="subcategorySelect"]');
    console.log(subcategorySelect)
    subcategorySelect.innerHTML = "";
    subcategories.forEach((subcategory) => {
      const option = document.createElement("option");
      option.value = subcategory.id;
      option.text =  subcategory.name;
      subcategorySelect.appendChild(option);
    });
   
  }
}
