import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["categorias", "notify", "avatar"]
  
  connect() {
  }

  toggle() {
    this.messageTarget.classList.toggle('hidden')
  }

  show_category(){
    this.categoriasTarget.classList.toggle('hidden')
  }

  avatar(){
    this.avatarTarget.classList.toggle('hidden')
  }
}
