import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sidebar", "contenido"]
  connect() {
    console.log("Ingreso modale delete")
  }

  open(event){

    const clickedButton = event.currentTarget;
    const toolId =    clickedButton.dataset.modalDeleteToolId; // Convert to number
    const toolIndex = this.sidebarTarget.dataset.modalDeleteToolIndex; // Convert to number
    console.log("Clicked tool IDxxxx:", toolId);

    this.contenidoTarget.classList.remove("hidden");
    alert("Delete toolId: " + toolId);
  }

  close(){
    console.log("Close entro");
    this.contenidoTarget.classList.add("hidden");
  }


}
